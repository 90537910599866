import React from 'react'

const Jobs = ({ Totaljobs }) => {

    return (
        <>

            <div>
                <h3 className="text-lg font-bold mb-2">{Totaljobs} Jobs</h3>
            </div>
            {/* <section>
                {result}
            </section> */}
        </>
    )
}
export default Jobs;
